<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="current_profile.id == null">
            <h2 class="title is-3">Создать пользователя</h2>
          </div>
          <div class="column" v-if="current_profile.id != null">
            <h2 class="title is-3">Изменить пользователя: {{ currentProfileName  }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row wrap>
          <v-layout column>
            <v-text-field
              outlined
              dense
              inputmode="email"
              v-model="current_profile.email"
              label="Email"
              :rules="emailRules"
            ></v-text-field>
          </v-layout>
          <v-layout column>
            <v-text-field
              outlined
              dense
              hide-details
              v-model="current_profile.name"
              label="Имя"></v-text-field>
          </v-layout>
          <v-layout column>
            <v-text-field
              outlined
              dense
              hide-details
              v-model="current_profile.phone"
              label="Телефон"></v-text-field>
          </v-layout>
        </v-layout>
        <v-layout row>
          <v-layout column>
            <v-text-field
              outlined
              dense
              password-reveal
              v-model="current_profile.password"
              label="Пароль"></v-text-field>
          </v-layout>
          <v-layout column>
            <v-select
              outlined
              dense
              hide-details
              :items="filteredRoles"
              label="Роль"
              :disabled="Boolean(current_profile.id === authUser.id && haveSuperuser)"
              v-model="current_profile.role"
            ></v-select>
          </v-layout>
          <v-layout column v-if="current_profile.role === 'user'">
            <v-select
              outlined
              dense
              hide-details
              :items="teamleads"
              item-text="name"
              item-value="id"
              label="Начальник"
              :disabled="current_profile.id === authUser.id"
              v-model="current_profile.chief_id"
            ></v-select>
          </v-layout>
        </v-layout>
        <v-layout row>
          <v-layout column>
            <v-select
              outlined
              dense
              hide-details
              :items="statuses"
              item-value="id"
              item-text="name"
              label="Статус"
              :disabled="current_profile.id === authUser.id"
              v-model="current_profile.status"
            ></v-select>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="current_profile.id == null" @click="createUser()">Добавить</v-btn>
        <v-btn text color="primary" v-if="current_profile.id != null" @click="updateUser()">Обновить</v-btn>
      </v-card-actions>
    </v-card>

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';

export default {
  name: 'profileDetailsModal',
  components: {
    OverlayLoader,
  },
  props: [
    'params_all',
    'params_teamlead',
  ],
  data() {
    return {
      roles: ['admin', 'user', 'teamlead', 'executor', 'superuser'],
      statuses: [
        { id: true, name: 'Активный' },
        { id: false, name: 'Заблокирован' },
      ],
      emailRules: [
        v => !v || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || 'Введите корректный email адрес'
      ],

      currentProfileName: '', // Копия имени профиля
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        // Когда диалог открывается, создаем копию имени профиля
        this.currentProfileName = this.current_profile.name;
      }
    },
  },
  created() {
    this.$store.dispatch("GET_SUPERUSER");
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_PROFILE_DETAILS_LOADING;
    },
    filteredRoles() {
      if (this.current_profile.id === this.authUser.id && this.haveSuperuser) {
        return this.roles;
      }
      return this.current_profile.role !== 'admin'
        ? this.roles.filter(role => role !== 'superuser')
        : this.roles;
    },
    haveSuperuser() {
      return this.$store.getters.HAVE_SUPERUSER;
    },
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    isOpen: {
      get() {
        return this.$store.getters.IS_PROFILE_DETAILS_MODAL_OPENED;
      },
      set() {
        this.$store.dispatch('PROFILE_DETAILS_MODAL', false);
      },
    },
    teamleads() {
      return this.$store.getters.GET_TEAMLEAD_DATA;
    },
    current_profile: {
      get() {
        return this.$store.getters.GET_CURRENT_PROFILE;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_PROFILE", value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.$store.commit('SET_DEFAULT_PROFILE');
      this.$store.dispatch('PROFILE_DETAILS_MODAL', false);
    },
    createUser() {
      this.$store
        .dispatch("CREATE_PROFILE")
        .then(() => {
          this.$toast.success("Новый пользователь создан");
          this.$store.dispatch("GET_PROFILES", this.params_all);
          this.$store.dispatch("GET_PROFILES", this.params_teamlead);
          this.close();
        })
        .catch(() => {
          this.$toast.error("Неизвестная ошибка!");
        });
    },
    updateUser() {
      this.$store
        .dispatch("UPDATE_PROFILE", this.current_profile)
        .then(() => {
          this.$toast.success("Пользователь изменен!");
          this.$store.dispatch("GET_PROFILES", this.params_all);
          this.$store.dispatch("GET_PROFILES", this.params_teamlead);
          this.close();
        })
        .catch((error) => {
          if (error === 403) {
            this.$toast.error("Недостаточно прав!");
          } else {
            this.$toast.error("Неизвестная ошибка!");
          }
        });
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    }
  },
};
</script>
