<template>
    <v-dialog
            v-model="isOpen"
            max-width="700"
    >
        <v-card>
            <v-card-title class="headline pb-10">
                Вы точно хотите удалить выбранного пользователя {{currentProfile.name}}?
            </v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="close()">Закрыть</v-btn>
                <v-btn text color="primary" @click="deleteProfile()">Удалить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import OverlayLoader from '@/app/shared/components/app-loader/Loader';

    export default {
        name: 'confirmDeletionModal',
        components: {
            OverlayLoader,
        },
        props: [
            'params_all',
            'params_teamlead',
        ],
        data() {
            return {
            };
        },
        computed: {
            isOpen: {
                get(){
                    return this.$store.getters.IS_CONFIRM_DELETION_MODAL_OPENED;
                },
                set(){
                    this.$store.dispatch('CONFIRM_DELETION_MODAL', false);
                }
            },
            currentProfile: {
              get() {
                return this.$store.getters.GET_CURRENT_PROFILE;
              },
              set(value) {
                this.$store.commit("SET_CURRENT_PROFILE", value);
              },
            },
        },
        methods: {
            close() {
                this.$store.dispatch('CONFIRM_DELETION_MODAL', false);
            },
            deleteProfile() {
                this.$store.dispatch('DELETE_SELECTED_PROFILE', this.currentProfile.id)
                    .then(() => {
                        this.$toast.success('Пользователь удален');
                        this.$store.dispatch("GET_PROFILES", this.params_all);
                        this.$store.dispatch("GET_PROFILES", this.params_teamlead);
                        this.$store.dispatch('CONFIRM_DELETION_MODAL', false);
                    })
                    .catch(() => {
                        this.$toast.error('Неизвестная ошибка');
                    });
            }
        },
    };
</script>
