<template>
  <div>
    <v-overlay :value="show" z-index="100">
      <v-progress-circular
        :size="80"
        color="spinner"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
    export default {
        name: 'OverlayLoader',
        props: [
            'show',
        ],
    };
</script>
