<template>
  <v-container fluid>

    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Список пользователей ({{ profiles.length }})</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <v-text-field
          outlined
          dense
          hide-details
          clearable
          label="Имя"
          v-model="name"
          @click:clear="onClear"
          @input="filterProfiles"
        >
        </v-text-field>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="teamleads"
          item-value="id"
          item-text="name"
          label="Начальник"
          v-model="chief_id"
          @input="filterProfiles"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="roles"
          item-value="value"
          item-text="name"
          label="Роль"
          v-model="role"
          @input="filterProfiles"
        ></v-select>
      </div>
      <div class="column">
        <b-button
          class="is-pulled-right"
          type="is-primary"
          icon-right="plus"
          @click="createProfile()"
        >Добавить</b-button
        >
      </div>
    </div>

    <div v-if="!haveSuperuser" class="title is-5 error--text">Назначьте суперпользователя, сейчас его нет</div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="profiles"
      class="users-table"

      :per-page="perPage"
      paginated
    >
      <b-table-column label="ID" v-slot="props">
        <span>{{ props.row.id }}</span>
      </b-table-column>

      <b-table-column label="Имя" v-slot="props">
        <span>{{ props.row.name }}</span>
      </b-table-column>

      <b-table-column label="Роль" v-slot="props">
        <span>{{ props.row.role }}</span>
      </b-table-column>

      <b-table-column label="Начальник" v-slot="props">
        <span>{{ getChiefName(props.row.chief_id) }}</span>
      </b-table-column>

      <b-table-column label="Статус" v-slot="props">
        <span>{{ props.row.status? 'Активный' : 'Блок'}}</span>
      </b-table-column>

      <b-table-column label="Действия" v-slot="props">
        <div>
          <b-tooltip label="Редактировать">
            <b-button class="edit_icon" @click="updateProfile(props.row)" :disabled="authUser.role === 'admin' && ['admin', 'superuser'].includes(props.row.role)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4"></path>
                <path d="M13.5 6.5l4 4"></path>
              </svg>
            </b-button>
          </b-tooltip>

          <b-tooltip label="Удалить">
            <b-button
              :disabled="authUser.role === 'admin' && ['admin', 'superuser'].includes(props.row.role)"
              class="delete_icon"
              @click="deleteProfile(props.row)">
              <svg viewBox="0 0 24 24" fill="none" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                <path d="M4 7l16 0"></path>
                <path d="M10 11l0 6"></path>
                <path d="M14 11l0 6"></path>
                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
              </svg>
            </b-button>
          </b-tooltip>
        </div>
      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            style="width: fit-content; float: left;"
            v-model="perPage"
            :items="perPageOptions"
            @change="filterProfiles"
            item-text="name"
            item-value="value"
            outlined
            hide-details
            dense
          />
        </div>
      </template>

    </b-table>

    <confirmDeletionModal :params_all="params_all" :params_teamlead="params_teamlead"></confirmDeletionModal>
    <profileDetailsModal
      @close="getProfiles"
      :params_all="params_all"
      :params_teamlead="params_teamlead">
    </profileDetailsModal>
  </v-container>
</template>

<script>
import confirmDeletionModal from '@/app/client/profiles/shared/modals/confirmDeletionModal';
import profileDetailsModal from '@/app/client/profiles/shared/modals/profileDetailsModal';

export default {
  name: "ProfileTable",
  components: {
    confirmDeletionModal,
    profileDetailsModal,
  },
  data() {
    return {
      loading: false,
      deletedProfile: null,
      updatedProfile: null,
      name: "",
      chief_id: "",
      role: "",

      /* для пагинации */
      perPage: 20,
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  created() {
    this.getProfiles();
    this.getTeamLeads();
  },
  computed: {
    haveSuperuser() {
      return this.$store.getters.HAVE_SUPERUSER;
    },
    authUser() {
      return this.$store.getters.AUTH_USER;
    },
    roles() {
      return (this.authUser.role === 'admin' || this.authUser.role ==='superuser') ? [
        {
          name: "Администратор",
          value: "admin",
        },
        {
          name: "Тимлид",
          value: "teamlead",
        },
        {
          name: "Пользователь",
          value: "user",
        },
        {
          name: "Разработчик приложений",
          value: "executor",
        },
        {
          name: "Суперпользователь",
          value: "superuser",
        }
      ] : [{
        name: "Пользователь",
        value: "user",
      },
      ];
    },
    profiles: {
      get() {
        return this.$store.getters.GET_PROFILES;
      },
      set(value) {
        this.$store.commit("SET_PROFILES", value);
      },
    },
    teamleads() {
      return this.$store.getters.GET_TEAMLEAD_DATA;
    },
    params_all() {
      return {
        filters: {},
        type: 'SET_PROFILES',
      };
    },
    params_teamlead() {
      return {
        filters: { role: 'teamlead', status: 'true' },
        type: 'SET_TEAMLEADS',
      };
    },
  },
  methods: {
    getChiefName(chiefId) {
      const profile = this.profiles.find(o => o.id === chiefId);
      return profile && profile.name ? profile.name : 'Нет';
    },
    getProfiles() {
      this.loading = true;
      const params = {
        filters: {},
        type: 'SET_PROFILES',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {
          this.$store.dispatch("GET_SUPERUSER");
        })
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getTeamLeads() {
      this.loading = true;
      const params = {
        filters: { role: 'teamlead', status: 'true' },
        type: 'SET_TEAMLEADS',
      };
      this.$store
        .dispatch("GET_PROFILES", params)
        .then((data) => {})
        .catch((error) => {
          this.$toast.error("Неизвестная ошибка!");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createProfile() {
      this.$store.dispatch("PROFILE_DETAILS_MODAL", true);
    },
    deleteProfile(profile) {
      this.$store.dispatch("CURRENT_PROFILE", profile);
      this.$store.dispatch("CONFIRM_DELETION_MODAL", true);
    },
    updateProfile(profile) {
      this.$store.dispatch("CURRENT_PROFILE", profile);
      this.$store.dispatch("PROFILE_DETAILS_MODAL", true);
    },
    onClear() {
      const params = {
        filters: {},
        type: 'SET_PROFILES',
      };
      this.$store.dispatch("GET_PROFILES",params);
    },
    filterProfiles() {
      const params = {
        filters: {
          name: this.name,
          chief_id: this.chief_id,
          role: this.role,
        },
        type: 'SET_PROFILES',
      };
      this.$store.dispatch("GET_PROFILES", params);
    },
  },
};
</script>

<style>
.profile-table {
  overflow: auto;
  font-size: 12px;
}
</style>
